import apiClient from ".";

export const listActivities = async (token) => {
  try {
    const response = await apiClient.get("/activities/list", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    return { success: false, message: error.message };
  }
};
