import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Modal, Select } from "antd";
import Header from "./components/Header";
import WaveSvg from "./components/WaveSvg";
import { createChurch, getChurch } from "../apis/church";
import { openNotificationWithIcon } from "../utils/notification";
import logo from "../assets/logo.png";

const Home = () => {
  const navigate = useNavigate();
  const [showAddChurchPopup, setShowAddChurchPopup] = useState(false);
  const [churchData, setChurchData] = useState({});
  const [formData, setFormData] = useState({
    churchName: "",
    address: "",
    responsiblePerson: "",
    phone: "",
  });

  const fetchChurchData = useCallback(async () => {
    const token = localStorage.getItem("token") || sessionStorage.getItem("token");
    if (!token) {
      navigate("/");
      return;
    }

    const storedChurch = localStorage.getItem("church");
    if (storedChurch) {
      setChurchData(JSON.parse(storedChurch));
      return;
    }

    const response = await getChurch(token);
    if (!response.success) {
      setShowAddChurchPopup(true);
    } else {
      const churchInfo = response.church;
      localStorage.setItem("church", JSON.stringify(churchInfo));
      setChurchData(churchInfo);
    }
  }, [navigate]);

  useEffect(() => {
    fetchChurchData();
  }, [fetchChurchData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "phone" && isNaN(value) ? prevData.phone : value,
    }));
  };

  const handleAddChurch = async () => {
    const { churchName, address, responsiblePerson, phone } = formData;
    const token = localStorage.getItem("token") || sessionStorage.getItem("token");

    if (!churchName || !address || !responsiblePerson || !phone) {
      openNotificationWithIcon("error", "خطأ", "الرجاء ملء جميع الحقول");
      return;
    }

    const response = await createChurch(churchName, address, token, responsiblePerson, phone);
    if (response.success) {
      openNotificationWithIcon("success", "نجاح", "تم إضافة الكنيسة بنجاح");
      setShowAddChurchPopup(false);
      window.location.reload();
    } else {
      openNotificationWithIcon("error", "خطأ", response.message);
    }
  };

  const handleCancelPopup = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    localStorage.removeItem("church");
    navigate("/");
  };

  const renderMenuItem = (text, onClick, href) => (
    <MDBCol className="sub-titlebaground home" style={{ cursor: "pointer" }} onClick={onClick}>
      {href ? (
        <a href={href} target="_blank" rel="noreferrer" style={{ color: "white", fontSize: "1.5em" }}>
          {text}
        </a>
      ) : (
        <div style={{ color: "white", fontSize: "1.5em" }}>{text}</div>
      )}
    </MDBCol>
  );

  return (
    <>
      <Header churchData={churchData} />
      <div className="background-radial-gradient" style={{ minHeight: "100vh", paddingBlock: "5rem" }}>
        <MDBContainer fluid>
          <MDBRow className="justify-content-center">
            <MDBCol md="8" className="text-center">
              <img src={logo} alt="logo" className="d-block mx-auto mb-2" style={{ width: "300px" }} />
              <h1 className="title-text">الأمانة العامة لخدمة ثانوى بالاسكندرية</h1>
              <div style={{ display: "flex", flexDirection: "column", gap: "1.5rem", alignItems: "center", marginTop: "6rem" }}>
                {renderMenuItem("كراسة الشروط", null, "https://drive.google.com/file/d/1oBLnHz48tcam6cRjO497Patyrag2Rw2w/view?usp=sharing")}
                {renderMenuItem("الاشتراك في المهرجان", () => navigate('/all-activities'))}
                {renderMenuItem("نتيجة المهرجان", () => navigate('/result'))}
                {churchData.name && renderMenuItem(<>
                  كنيستك :{" "}
                  <span style={{ textShadow: "2px 2px 4px #ff8000" }}>
                    {" "}
                    {churchData.name}{" "}
                  </span>
                </>, () => navigate('/church'))}
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <WaveSvg />
      </div>
      <Modal
        visible={showAddChurchPopup}
        closable={false}
        footer={null}
        style={{ direction: "rtl", textAlign: "center", fontFamily: "Cairo", fontSize: "1.5rem" }}
      >
        <form style={{ padding: "1rem", display: "flex", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
          <h2>الرجاء إضافة كنيستك</h2>
          <input
            type="text"
            className="form-control"
            placeholder="اسم الكنيسة"
            name="churchName"
            required
            onChange={handleInputChange}
          />
          <input
            type="text"
            className="form-control"
            placeholder="الخادم المسؤول"
            name="responsiblePerson"
            required
            onChange={handleInputChange}
          />
          <input
            type="text"
            className="form-control"
            placeholder="رقم الهاتف"
            name="phone"
            required
            onChange={handleInputChange}
          />
          <Select
            placeholder="القطاع"
            style={{ width: "100%", fontFamily: 'Cairo' }}
            onChange={(value) => setFormData((prevData) => ({ ...prevData, address: value }))}
          >
            {["شرق 1", "شرق 2", "غرب", "وسط", "منتزة 1", "منتزة 2"].map((sector) => (
              <Select.Option key={sector} value={sector}>{sector}</Select.Option>
            ))}
          </Select>
          <div style={{ display: "flex", gap: "1rem" }}>
            <button type="button" className="btn btn-primary" onClick={handleAddChurch}>
              إضافة
            </button>
            <button type="button" className="btn btn-danger" onClick={handleCancelPopup}>
              إلغاء
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default Home;